<template>
  <div class="card-container flex flex-col" style="padding: 20px; padding-left: 30px; gap: 24px">
    <p class="section-title">{{ displayData.title }}</p>

    <GrayBlockContainer>
      <div
        v-for="(item, idx) in cart"
        :key="item.id"
        class="flex flex-col"
        style="gap: 24px"
      >
        <div class="flex justify-between items-center">
          <p class="section-title">{{ $t('appointmentOtherServicesMode.serviceIndex.title', { index: idx + 1 }) }}</p>
          <div v-if="idx !== 0" class="remove-period-btn" @click="removeService(item.cartItem)">
            <SVGIcon icon="common-trash" fill="var(--gray-60)" />
          </div>
        </div>
        <div>
          <AppointmentCoreFlowBlock
            :cartItemId="item.id"
            :cartItem.sync="cart[idx].cartItem"
          />
          <el-divider v-if="idx !== cart.length - 1" />
        </div>
      </div>
      <div class="flex items-center" style="gap: 24px;">
        <BaseElButton type="primary" style="padding: 8.5px 12px;" :disabled="disabledControl.addService" @click="onAddService">
          <div class="flex items-center" style="gap: 4px">
            <p>{{ displayData.addService }}</p>
            <MaterialIcon>add</MaterialIcon>
          </div>
        </BaseElButton>
        <div>
          {{ displayData.maxServiceCount }}<span class="text-danger">{{ displayData.remainingServiceCount }}</span>{{ displayData.serviceItemUnit }}
        </div>
      </div>
    </GrayBlockContainer>
  </div>
</template>

<script>
import { useCreateAappointment } from '@/use/useCreateAppointment'
import { computed, defineComponent } from 'vue'
import AppointmentCoreFlowBlock from './AppointmentCoreFlowBlock.vue'
import GrayBlockContainer from '@/components/Container/GrayBlockContainer.vue'
import { i18n } from '@/plugins/i18n/i18n'

export default defineComponent({
  name: 'AppointmentOtherServicesMode',
  components: {
    AppointmentCoreFlowBlock,
    GrayBlockContainer,
  },
  setup (props, { emit }) {
    const {
      cart,
      addAppointmentCartItem,
      removeAppointmentCartItem,
      allCartItemReady,
    } = useCreateAappointment()

    const maxServiceCount = 4

    const displayData = computed(() => {
      const remainingServiceCount = maxServiceCount - cart.value.length
      const data = {
        title: i18n.t('appointmentOtherServicesMode.title'),
        addService: i18n.t('appointmentOtherServicesMode.button.addService.text'),
        maxServiceCount: i18n.t('appointmentOtherServicesMode.maxServiceCount.title', { maxSerivceCount: maxServiceCount }),
        serviceItemUnit: i18n.t('appointmentOtherServicesMode.serviceItemUnit.title'),
        remainingServiceCount,
      }
      return data
    })

    const disabledControl = computed(() => {
      const controls = {
        addService: false,
      }

      if (cart.value.length >= maxServiceCount) {
        controls.addService = true
      }

      if (!allCartItemReady.value) {
        controls.addService = true
      }

      return controls
    })

    const onAddService = () => {
      // const preCartItem = cart.value[cart.value.length - 1]
      // const preCartItemData = get(preCartItem, 'cartItem')
      // let date
      // let time
      // if (preCartItemData) {
      //   const preDate = get(preCartItemData, 'date')
      //   const preNextStart = get(preCartItemData.getDetailData('reservation'), 'nextStart')
      //   const secondOrderStartDefault = get(configData.reservation, 'secondOrderStartDefault')
      //   if (secondOrderStartDefault === 'same') {
      //     date = preDate
      //     time = preNextStart
      //   } else {
      //     date = dayjs(preNextStart).startOf('d')
      //     time = secondOrderStartDefault
      //   }
      // }
      addAppointmentCartItem({
        isOther: true,
      })
    }

    const removeService = (cartItem) => {
      removeAppointmentCartItem(cartItem.nanoId)
    }
    return { cart, onAddService, removeService, disabledControl, displayData }
  },
})
</script>

<style lang="postcss" scoped>
.section-title {
  @apply font-bold text-primary-100 text-[18px];
}

.remove-period-btn {
  @apply cursor-pointer;
}

</style>
