<template>
  <div class="card-container" style="padding: 20px; padding-left: 30px">
    <BaseElFormItem :label="displayData.comment.label">
      <BaseElInput
        v-model="context.comment"
        type="textarea"
        show-word-limit
        maxlength="150"
        :autosize="{ minRows: 5, maxRows: 6}"
        :placeholder="displayData.comment.placeholder"
      />
    </BaseElFormItem>
    <BaseElFormItem :label="displayData.shopNote.label">
      <BaseElInput
        v-model="context.shopNote"
        type="textarea"
        show-word-limit
        maxlength="150"
        :autosize="{ minRows: 5, maxRows: 6}"
        :placeholder="displayData.shopNote.placeholder"
      />
    </BaseElFormItem>
  </div>
</template>

<script>
import { useCreateAappointment } from '@/use/useCreateAppointment'
import { computed, defineComponent } from 'vue'
import { i18n } from '@/plugins/i18n/i18n'

export default defineComponent({
  name: 'AppointmentOtherInfoBlock',
  setup (props, { emit }) {
    const { context } = useCreateAappointment()
    const displayData = computed(() => {
      return {
        comment: {
          label: i18n.t('editReservationDialog.comment.title'),
          placeholder: i18n.t('editReservationDialog.comment.placeholder'),
        },
        shopNote: {
          label: i18n.t('editReservationDialog.remark.title'),
          placeholder: i18n.t('editReservationDialog.remark.placeholder'),
        },
      }
    })
    return { displayData, context }
  },
})
</script>

<style lang="postcss" scoped>

</style>
