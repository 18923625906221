<template>
  <el-time-picker
    v-model="model"
    v-bind="$attrs"
    format="HH:mm"
    @change="onTimeChange"
  />
</template>

<script>
import { defineComponent, onBeforeMount, ref, watch } from 'vue'
import dayjs from '@/lib/dayjs'

export default defineComponent({
  name: 'BaseTimePicker',
  props: {
    value: {
      type: Date,
      default: '',
    },
    date: {
      type: [String, Date],
      default: '',
    },
  },
  setup (props, { emit }) {
    const model = ref('')

    const onTimeChange = () => {
      const date = dayjs(props.date).format('YYYY-MM-DD')
      const time = dayjs(model.value).format('HH:mm')
      const value = dayjs(`${date} ${time}`).toDate()
      emit('update:value', value)
      emit('change', value)
    }

    watch(() => props.value, (value) => {
      model.value = value
    })

    onBeforeMount(() => {
      model.value = props.value
    })

    return { model, onTimeChange }
  },
})
</script>

<style lang="postcss" scoped>

</style>
