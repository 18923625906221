<template>
  <div class="appointment-batch-order-mode">
    <div class="card-container" style="padding: 20px; padding-left: 30px">
      <p class="section-title">{{ displayText.title }}</p>
      <AppointmentCoreFlowBlock
        :cartItem.sync="cart[0].cartItem"
        hideDateTimeSelector
        hideClassTicketSelector
      />
    </div>
    <AppointmentMultiplePeriodBlock v-if="showControl.block.periodBlock" />
  </div>
</template>

<script>
import { useCreateAappointment } from '@/use/useCreateAppointment'
import { computed, defineComponent } from 'vue'
import AppointmentMultiplePeriodBlock from './AppointmentMultiplePeriodBlock.vue'
import AppointmentCoreFlowBlock from './AppointmentCoreFlowBlock.vue'
import { get } from 'lodash'
import { i18n } from '@/plugins/i18n/i18n'

export default defineComponent({
  name: 'AppointmentBatchOrderMode',
  components: {
    AppointmentMultiplePeriodBlock,
    AppointmentCoreFlowBlock,
  },
  setup (props, { emit }) {
    const { cart } = useCreateAappointment()
    const mainPeriodCartItem = computed(() => get(cart.value, '[0].cartItem'))
    const displayText = computed(() => {
      const data = {
        title: i18n.t('reservationOrderDetail.title'),
      }
      return data
    })
    const showControl = computed(() => {
      const controls = {
        block: {
          periodBlock: false,
        },
      }

      if (get(mainPeriodCartItem.value, 'service')) {
        controls.block.periodBlock = true
      }

      return controls
    })
    return { cart, showControl, displayText }
  },
})
</script>

<style lang="postcss" scoped>
.appointment-batch-order-mode {
  @apply flex flex-col gap-[24px];
}

.section-title {
  @apply font-bold text-primary-100 text-[18px] mb-[24px];
}
</style>
