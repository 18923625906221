<template>
  <div class="new-create-appointment">
    <PageTitle
      :title="displayText.title"
      hideBtn
      icon="chevron_left"
      @iconClick="$router.go(-1)"
    />
    <BaseElForm
      v-if="loaded"
      ref="formRef"
      :model="context"
      :rules="formRules"
      label-position="top"
      class="form-wrapper"
    >
      <AppointmentBaseInfoBlock
        @modeChange="onModeChange"
      />

      <transition name="el-fade-in-linear">
        <template v-if="!refreshing.mode">
          <!-- 一般模式 -->
          <AppointmentNormalMode v-if="showControl.normalMode" />
          <!-- 多時段模式 -->
          <AppointmentBatchOrderMode v-if="showControl.batchOrderMode" />
          <!-- 多服務模式 -->
          <AppointmentOtherServicesMode v-if="showControl.otherReservationMode" />
        </template>
      </transition>

      <transition name="el-fade-in-linear">
        <AppointmentPaymentBlock v-if="showControl.paymentInfo" />
      </transition>
      <AppointmentOtherInfoBlock />
    </BaseElForm>

    <PageFixedFooter
      :confirmLoading="loading.submit"
      @confirm="onCreateAppointment"
      @cancel="onCancel"
    />
  </div>
</template>

<script>
import { appointmentModeType, useCreateAappointment } from '@/use/useCreateAppointment'
import { computed, defineComponent, nextTick, onBeforeMount, onBeforeUnmount, reactive, ref } from 'vue'
import AppointmentBaseInfoBlock from './components/AppointmentBaseInfoBlock.vue'
import AppointmentOtherInfoBlock from './components/AppointmentOtherInfoBlock.vue'
import AppointmentBatchOrderMode from './components/AppointmentBatchOrderMode.vue'
import AppointmentPaymentBlock from './components/AppointmentPaymentBlock.vue'
import AppointmentOtherServicesMode from './components/AppointmentOtherServicesMode.vue'
import PageFixedFooter from '@/components/Footer/PageFixedFooter.vue'
import { useRoute, useRouter } from 'vue-router/composables'
import { get, find } from 'lodash'
import { CreateAppointmentOrderV2, CreateBatchAppointmentOrderV2 } from '@/api/reservation'
import { maxNumberRules, noEmptyRules } from '@/validation'
import AppointmentNormalMode from './components/AppointmentNormalMode.vue'

import formUtils from '@/utils/form'
import PageTitle from '@/components/Title/PageTitle.vue'
import { FindMember } from '@/api/member'
import { apiFormatAdaptor } from '@/utils/api'
import { useShop } from '@/use/shop'
import { i18n } from '@/plugins/i18n/i18n'

export default defineComponent({
  name: 'NewCreateAppointment',
  components: {
    PageTitle,
    AppointmentBaseInfoBlock,
    AppointmentBatchOrderMode,
    AppointmentPaymentBlock,
    AppointmentOtherInfoBlock,
    AppointmentOtherServicesMode,
    AppointmentNormalMode,
    PageFixedFooter,
  },
  setup (props) {
    const {
      memberData,
      context, init, configData, resourceData, cart, addAppointmentCartItem,
      resetCart, resetContext,
      allCartItemReady,
      formatBatchOrderPayload,
      formatOrderPayload,
      composeAllCartItemReservations,
      getMemberResourceData,
    } = useCreateAappointment()
    const formRef = ref(null)
    const loaded = ref(false)
    const router = useRouter()
    const route = useRoute()
    const { shopId } = useShop()
    const displayText = computed(() => {
      const data = {
        title: i18n.t('newCreateAppointment.title')
      }
      return data
    })
    const showControl = computed(() => {
      const controls = {
        normalMode: false,
        otherReservationMode: false,
        batchOrderMode: false,
        paymentInfo: false,
      }

      const toggleMode = () => {
        if (context.appointmentMode === appointmentModeType.normal) {
          controls.normalMode = true
        } else if (context.appointmentMode === appointmentModeType.otherReservation) {
          controls.otherReservationMode = true
        } else if (context.appointmentMode === appointmentModeType.batchOrder) {
          controls.batchOrderMode = true
        }
      }

      if (context.userType === 'member') {
        if (context.member) toggleMode()
      } else if (context.userType === 'guest') {
        toggleMode()
      }

      if (allCartItemReady.value) {
        controls.paymentInfo = true
      }

      return controls
    })

    const loading = reactive({
      submit: false,
    })

    const refreshing = reactive({
      mode: false,
    })

    // 基本表單規則
    const formRules = computed(() => {
      const rules = {
        member: [noEmptyRules()],
        'payment.method': [noEmptyRules()],
      }

      if (context.userType === 'guest') {
        delete rules.member
        rules['guest.name'] = [noEmptyRules()]
        rules['guest.phone'] = [noEmptyRules()]
      }

      // 根據不同預約模式生成不同的規則
      if (context.appointmentMode === appointmentModeType.normal) {
        rules.service = [noEmptyRules()]
        rules.serviceUnit = [noEmptyRules()]
      } else if (context.appointmentMode === appointmentModeType.batchOrder) {
        rules.service = [noEmptyRules()]
        rules.serviceUnit = [noEmptyRules()]
      } else if (context.appointmentMode === appointmentModeType.otherReservation) {
        rules.peopleCount = [maxNumberRules(1)]

        const cartItemCount = cart.value.length
        for (let count = 0; count < cartItemCount; count++) {
          rules[`[${count}].service`] = [noEmptyRules()]
          rules[`[${count}].serviceUnit`] = [noEmptyRules()]
        }
      }

      const chargeType = get(context.checkoutDetail, 'chargeType')

      if (get(configData.checkoutInvoice, 'enable') && chargeType === 'checkout') {
        rules['invoice.email'] = [noEmptyRules()]
        if (context.invoice.type === 'B') {
          rules['invoice.companyTitle'] = [noEmptyRules()]
          rules['invoice.taxId'] = [noEmptyRules()]
        }
      }

      if (get(configData.checkout, 'enableCashbackDeduction') && chargeType === 'checkout') {
        const cashbackBalance = get(memberData, 'cashback.balance')
        const cashbackLimit = get(context, 'discount.cashback.limit', 0)
        let maxValue
        if (cashbackBalance < cashbackLimit) {
          maxValue = cashbackBalance
        } else {
          maxValue = cashbackLimit
        }

        rules['discount.cashback.use'] = [maxNumberRules(maxValue)]
      }

      return rules
    })

    // 檢查所有購物車項目表單
    const checkCartItemsForm = async () => {
      let allPass = true
      for (const cartItem of cart.value) {
        const formRef = get(cartItem, 'formRef')
        if (formRef) {
          const formPass = await formUtils.checkForm(formRef)
          if (!formPass) allPass = false
        }
      }
      return allPass
    }

    // 檢查多時段設定表單
    const checkPeriodSettingForm = async () => {
      let allPass = true
      for (const formRef of context.periodSettingFormRefs) {
        const formPass = await formUtils.checkForm(formRef)
        if (!formPass) allPass = false
      }
      return allPass
    }

    const checkCommonInfoForm = async () => {
      let pass = true
      const otherUserInfoformRef = get(context.otherUserInfo, 'formRef')
      if (otherUserInfoformRef) pass = await formUtils.checkForm(otherUserInfoformRef)
      return pass
    }

    // 建立預約訂單
    const onCreateAppointment = async () => {
      if (loading.submit) return
      loading.submit = true
      const appointmentMode = context.appointmentMode
      if (!await checkCartItemsForm() || !await checkCommonInfoForm() || !await formUtils.checkForm(formRef.value)) {
        window.$message.warning('尚有欄位未填寫，或填寫資訊有誤，請檢查 !')
        loading.submit = false
        return
      }
      if (appointmentMode === appointmentModeType.batchOrder) {
        if (!await checkPeriodSettingForm()) {
          window.$message.warning('多時段設定有誤或未填寫，請檢查 !')
          loading.submit = false
          return
        }
      }
      let apiMethod = CreateAppointmentOrderV2
      let payload = formatOrderPayload.value
      if (appointmentMode === appointmentModeType.batchOrder) {
        apiMethod = CreateBatchAppointmentOrderV2
        payload = formatBatchOrderPayload.value
      }

      const [res, err] = await apiMethod(payload)
      loading.submit = false
      if (err) {
        window.$message.error(err)
        return
      }

      window.$message.success('建立預約成功 !')

      resetCart()
      resetContext()
      router.go(-1)
    }

    const onCancel = () => {
      resetCart()
      resetContext()
      router.go(-1)
    }

    const checkQueryMember = async () => {
      const queryMember = route.query.member
      if (queryMember) {
        const [res, err] = await apiFormatAdaptor(FindMember)({
          shopId: shopId.value,
          id: queryMember,
        })
        if (err) {
          window.$message.error(err)
          return
        }
        context.member = res
        context.userType = 'member'

        getMemberResourceData('member')
        getMemberResourceData('classTickets')
        getMemberResourceData('cashback')
      }
    }

    const onModeChange = async () => {
      // 因為切換模式會重置購物車，導致 cartItem.formRef 變成 null
      // 因此要手動重新開關 coreFlowBlock 來觸發 onMounted 生命週期來更新 cartItem.formRef
      refreshing.mode = true
      await nextTick()
      setTimeout(() => {
        refreshing.mode = false
      }, 0)
    }

    onBeforeMount(async () => {
      await init()
      await checkQueryMember()
      loaded.value = true
    })

    onBeforeUnmount(() => {
      resetCart()
      resetContext()
    })

    return {
      loading,
      formRef,
      formRules,
      context,
      loaded,
      configData,
      resourceData,
      cart,
      addAppointmentCartItem,
      showControl,
      onCreateAppointment,
      onCancel,
      formatBatchOrderPayload,
      formatOrderPayload,
      composeAllCartItemReservations,
      onModeChange,
      refreshing,
      displayText,
    }
  },
})
</script>

<style lang="postcss" scoped>
.new-create-appointment {
}

.form-wrapper {
  @apply flex flex-col gap-[24px];
}
</style>
