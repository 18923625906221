<template>
  <div class="card-container" style="padding: 20px; padding-left: 30px">
    <p class="section-title">{{ displayText.title }}</p>
    <AppointmentCoreFlowBlock
      :cartItem.sync="cart[0].cartItem"
    />
  </div>
</template>

<script>
import { useCreateAappointment } from '@/use/useCreateAppointment'
import { defineComponent, computed } from 'vue'
import AppointmentCoreFlowBlock from './AppointmentCoreFlowBlock.vue'
import { i18n } from '@/plugins/i18n/i18n'

export default defineComponent({
  name: 'AppointmentNormalMode',
  components: {
    AppointmentCoreFlowBlock,
  },
  setup (props, { emit }) {
    const { cart } = useCreateAappointment()
    const displayText = computed(() => {
      const data = {
        title: i18n.t('reservationOrderDetail.title'),
      }
      return data
    })
    return { cart, displayText }
  },
})
</script>

<style lang="postcss" scoped>
.section-title {
  @apply font-bold text-primary-100 text-[18px] mb-[24px];
}
</style>
