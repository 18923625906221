<template>
  <div class="card-container" style="padding: 20px; padding-left: 30px">
    <BaseElFormItem :label="displayText.memberType">
      <BaseElRadioGroup v-model="context.userType">
        <BaseElRadio
          v-for="option in userTypeOptions"
          :key="option.value"
          :label="option.value"
          @change="onUserTypeChange"
        >
          {{ option.label }}
        </BaseElRadio>
      </BaseElRadioGroup>
    </BaseElFormItem>

    <BaseElFormItem :label="displayText.peopleCount">
      <BaseElInputNumber
        v-model="context.peopleCount"
        :min="1"
        :max="maxOrderMorePeopleCount"
        @change="onPeopleCountChange"
      />
    </BaseElFormItem>

    <BaseElFormItem :label="displayText.appointmentMode">
      <BaseElRadioGroup v-model="context.appointmentMode" @change="onAppointmentModeChange">
        <BaseElRadio
          v-for="option in appointmentModeOptions"
          :key="option.value"
          :label="option.value"
          :disabled="option.disabled"
        >
          <div class="inline-flex items-center" style="gap: 8px">
            <p>{{ option.label }}</p>
            <TipInfo :width="200">{{ option.tooltip }}</TipInfo>
          </div>
        </BaseElRadio>
      </BaseElRadioGroup>
    </BaseElFormItem>

    <BaseElFormItem v-if="context.userType === 'member'" :label="displayText.member" prop="member">
      <MemberSearch
        :model.sync="context.member"
        @change="onMemberChange"
        @clear="onMemberChange"
      />
    </BaseElFormItem>

    <template v-if="context.userType === 'guest'">
      <BaseElFormItem :label="displayText.guest.name" prop="guest.name">
        <BaseElInput v-model="context.guest.name" />
      </BaseElFormItem>
      <BaseElFormItem :label="displayText.guest.phone" prop="guest.phone">
        <BaseElInput v-model="context.guest.phone" />
      </BaseElFormItem>
    </template>
    <AppointmentOtherUserInfo />
  </div>
</template>

<script>
import MemberSearch from '@/components/Search/MemberSearch.vue'
import { useCreateAappointment, appointmentModeType } from '@/use/useCreateAppointment'
import { passValue } from '@/utils/helper'
import { forEach, filter, get } from 'lodash'

import { computed, defineComponent } from 'vue'
import AppointmentOtherUserInfo from './AppointmentOtherUserInfo.vue'
import TipInfo from '@/components/TipInfo.vue'
import { i18n } from '@/plugins/i18n/i18n'

export default defineComponent({
  name: 'AppointmentBaseInfoBlock',
  components: { MemberSearch, AppointmentOtherUserInfo, TipInfo },
  props: {},
  setup (props, { emit }) {
    const {
      cart,
      context,
      configData,
      resetCart, resetContext, updateCartItemData,
      getMemberResourceData,
      allCartItemReady,
      refreshOrderPrice,
    } = useCreateAappointment()
    const displayText = computed(() => {
      const data = {
        memberType: i18n.t('orderInfoBlock.memberType.title'),
        peopleCount: i18n.t('orderInfoBlock.peopleCount.title'),
        appointmentMode: i18n.t('orderInfoBlock.appointmentMode.title'),
        member: i18n.t('orderInfoBlock.memberType.member.title'),
        guest: {
          name: i18n.t('orderInfoBlock.guest.name.title'),
          phone: i18n.t('orderInfoBlock.guest.phone.title'),
        }
      }
      return data
    })
    const showControl = computed(() => {
      const controls = {}
      return controls
    })

    const maxOrderMorePeopleCount = computed(() => {
      if (context.appointmentMode === appointmentModeType.otherReservation) {
        return 1
      }
      return get(configData.reservation, 'maxOrderMorePeopleCount') || 1
    })

    const disabledControl = computed(() => {
      const controls = {
        appointmentModeOptions: {
          [appointmentModeType.otherReservation]: false,
        },
      }

      if (context.peopleCount > 1) {
        controls.appointmentModeOptions[appointmentModeType.otherReservation] = true
      }

      return controls
    })

    // 會員類別選項
    const userTypeOptions = computed(() => {
      return [
        { label: i18n.t('orderInfoBlock.memberType.member.title'), value: 'member' },
        { label: i18n.t('orderInfoBlock.memberType.nonMember.title'), value: 'guest' },
      ]
    })

    // 預約模式選項
    const appointmentModeOptions = computed(() => {
      const options = [
        { label: i18n.t('orderInfoBlock.appointmentModeOptions.normal.title'), value: appointmentModeType.normal, tooltip: i18n.t('orderInfoBlock.appointmentModeOptions.normal.tooltip') },
        { label: i18n.t('orderInfoBlock.appointmentModeOptions.batchOrder.title'), value: appointmentModeType.batchOrder, tooltip: i18n.t('orderInfoBlock.appointmentModeOptions.batchOrder.tooltip') },
        { label: i18n.t('orderInfoBlock.appointmentModeOptions.otherReservation.title'), value: appointmentModeType.otherReservation, tooltip: i18n.t('orderInfoBlock.appointmentModeOptions.otherReservation.tooltip'), disabled: disabledControl.value.appointmentModeOptions[appointmentModeType.otherReservation] },
      ]

      const omitList = []
      // const allowBatchOrder = get(configData.reservation, 'allowBatchOrder')
      // const allowOtherReservation = get(configData.reservation, 'allowOtherReservation')

      // if (!allowBatchOrder) omitList.push(appointmentModeType.batchOrder)
      // if (!allowOtherReservation) omitList.push(appointmentModeType.otherReservation)

      // if (context.peopleCount > 1) {
      //   omitList.push(appointmentModeType.otherReservation)
      // }

      return filter(options, (option) => !omitList.includes(option.value))
    })

    const onMemberChange = async (member) => {
      if (!member) return
      getMemberResourceData('member')
      getMemberResourceData('classTickets')
      getMemberResourceData('cashback')
      forEach(cart.value, (item) => {
        updateCartItemData(item.id, 'cartItem.classTickets', [])
      })

      if (allCartItemReady.value) {
        refreshOrderPrice()
      }
    }

    const onAppointmentModeChange = (appointmentMode) => {
      if (appointmentMode === appointmentModeType.otherReservation) {
        context.peopleCount = 1
      }
      resetCart()
      emit('modeChange')
    }

    const onUserTypeChange = (userType) => {
      const curAppointmentMode = passValue(context.appointmentMode)
      // const curPeopleCount = passValue(context.peopleCount)
      const otherUserInfoFormData = passValue(context.otherUserInfo.formData)
      resetCart()
      resetContext()
      context.userType = userType
      context.appointmentMode = curAppointmentMode
      // context.peopleCount = curPeopleCount
      context.otherUserInfo.formData = otherUserInfoFormData
    }

    const onPeopleCountChange = (peopleCount) => {
      const curAppointmentMode = passValue(context.appointmentMode)
      const curPeopleCount = passValue(context.peopleCount)
      const userType = passValue(context.userType)
      const otherUserInfoFormData = passValue(context.otherUserInfo.formData)
      resetCart()
      resetContext()
      context.userType = userType
      context.appointmentMode = curAppointmentMode
      context.peopleCount = curPeopleCount
      context.otherUserInfo.formData = otherUserInfoFormData
    }

    return {
      context,
      configData,
      showControl,
      appointmentModeType,
      userTypeOptions,
      appointmentModeOptions,
      onAppointmentModeChange,
      onMemberChange,
      onUserTypeChange,
      maxOrderMorePeopleCount,
      disabledControl,
      onPeopleCountChange,
      displayText,
    }
  },
})
</script>

<style lang="postcss" scoped>

</style>
